
import { transformDateToNumericLocaleString } from '@/@shared/@helpers/date-helper';
import { ITraining } from '@/training/@interfaces/ITraining';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent, ref } from 'vue';
import { IWorkout } from './@interfaces/IWorkout';
import WorkoutSelected from './WorkoutSelected.vue';
import useWorkoutStore from './@composables/useWorkoutStore';
import useTrainingStore from '@/training/@composables/useTrainingStore';
import PaginateComponent from '@/@shared/PaginateComponent.vue';

export default defineComponent({
  name: 'ResultsView',
  components: { FontAwesomeIcon, WorkoutSelected, PaginateComponent },
  setup() {
    const workoutStore = useWorkoutStore();
    const workouts = workoutStore.workouts;
    const paginatedWorkouts = ref<IWorkout[]>([]);

    const trainingStore = useTrainingStore();
    const trainings = trainingStore.trainings;

    const trainingSelected = ref<ITraining | null>(null);
    const workoutSelected = ref<IWorkout | null>(null);

    const getDateFormatted = (date: string) => {
      return transformDateToNumericLocaleString(date);
    };

    const updateWorkout = (workout: IWorkout) => {
      workoutSelected.value = workout;
      trainingSelected.value = trainingStore.getById(workout.trainingId);
    };

    const deleteWorkout = async (workout: IWorkout) => {
      await workoutStore.deleteData(workout.id);
    };

    const onUpdateDone = () => {
      workoutSelected.value = null;
      trainingSelected.value = null;
    };

    const onPagination = (event: unknown[]) => {
      paginatedWorkouts.value = event as IWorkout[];
    };

    return {
      workouts,
      paginatedWorkouts,
      trainings,
      trainingSelected,
      workoutSelected,
      getDateFormatted,
      updateWorkout,
      deleteWorkout,
      onUpdateDone,
      onPagination,
    };
  },
});
