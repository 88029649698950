import { computed, ref } from 'vue';

export default function usePagination(inputData: unknown[], pageSize = 10) {
  const activePage = ref(1);
  const totalPages = ref(Math.ceil(inputData.length / pageSize));

  function setActivePage(requestedPage: number) {
    activePage.value = requestedPage;
  }

  function getOutputData() {
    return inputData.slice(
      (activePage.value - 1) * pageSize,
      activePage.value * pageSize
    );
  }

  function getActivePage() {
    return activePage.value;
  }

  return {
    outputData: computed(() => getOutputData()),
    totalPages: computed(() => totalPages.value),
    activePage: computed(() => getActivePage()),
    pageSize,
    setActivePage,
  };
}
