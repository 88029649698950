import axios from 'axios';
import router from '@/@router';
import { ILogin } from '@/login/@interfaces/ILogin';
import { IUser } from '@/@shared/@interfaces/IUser';

export const authService = {
  login,
  logout,
  getAccessToken,
  refreshToken,
  getUserRole,
};

const userKey = 'user';

async function login(credentials: ILogin): Promise<void> {
  const response = await axios.post<IUser>('/user/authenticate', {
    usernameOrEmail: credentials.usernameOrEmail,
    password: credentials.password,
  });
  localStorage.setItem(userKey, JSON.stringify(response.data));
}

async function refreshToken(): Promise<void> {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const response = await axios.post<IUser>('token/refresh', {
    accessToken,
    refreshToken,
  });
  localStorage.setItem(userKey, JSON.stringify(response.data));
}

async function logout(): Promise<void> {
  localStorage.removeItem(userKey);
  await router.push('/login');
}

function getAccessToken(): string | null {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return null;
  }
  const userObject: IUser = JSON.parse(user);
  return userObject.accessToken;
}

function getRefreshToken(): string | null {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return null;
  }
  const userObject: IUser = JSON.parse(user);
  return userObject.refreshToken;
}

function getUserRole(): string | null {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return null;
  }
  const userObject: IUser = JSON.parse(user);
  return userObject.role;
}
