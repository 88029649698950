
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'FooterComponent',
  setup() {
    const appVersion = process.env.VUE_APP_VERSION;
    let currentYear = ref(2021);
    const getCurrentYear = () => {
      currentYear.value = new Date().getFullYear();
    };
    onMounted(getCurrentYear);
    return {
      appVersion,
      currentYear,
    };
  },
});
