
import { transformDateToNumericLocaleString } from '@/@shared/@helpers/date-helper';
import { ITraining } from '@/training/@interfaces/ITraining';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent, PropType, ref } from 'vue';
import useResultStore from './@composables/useResultStore';
import useWorkoutExerciseStore from './@composables/useWorkoutExerciseStore';
import { IResult } from './@interfaces/IResult';
import { IWorkout } from './@interfaces/IWorkout';
import { IWorkoutExercise } from './@interfaces/IWorkoutExercise';
import ResultForm from './ResultForm.vue';

export default defineComponent({
  name: 'WorkoutSelected',
  components: { FontAwesomeIcon, ResultForm },
  props: {
    trainingProp: {
      type: Object as PropType<ITraining>,
      required: true,
    },
    workoutProp: {
      type: Object as PropType<IWorkout>,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const displayResultForm = ref(false);
    const result = ref<IResult | null>(null);

    const resultStore = useResultStore(props.workoutProp);
    const results = resultStore.results;

    const workoutExerciseStore = useWorkoutExerciseStore(props.trainingProp.id);
    const workoutExercises = workoutExerciseStore.workoutExercises;
    const workoutExercise = workoutExerciseStore.workoutExerciseSelected;

    const toggleResultForm = (exercise: IWorkoutExercise | null) => {
      workoutExerciseStore.setWorkoutExerciseSelected(exercise);
      if (props.isUpdate) {
        result.value = findResult(exercise);
      }
      displayResultForm.value = !displayResultForm.value;
    };

    const handleFormSubmission = () => {
      workoutExerciseStore.setWorkoutExerciseToComplete();
      toggleResultForm(null);
    };

    const getDateFormatted = (date: string) => {
      return transformDateToNumericLocaleString(date);
    };

    const getResults = (exercise: IWorkoutExercise) => {
      const resultData = findResult(exercise);
      if (!resultData) return '/';
      const weight = resultData.weight ? `(${resultData.weight} kg)` : '';
      if (exercise.isTimedExercise) {
        return `${resultData.time} s ${weight}`;
      } else {
        return `${
          resultData.repetitionsSet1 +
          resultData.repetitionsSet2 +
          resultData.repetitionsSet3 +
          resultData.repetitionsSet4 +
          resultData.repetitionsSet5 +
          resultData.repetitionsSet6 +
          resultData.repetitionsSet7 +
          resultData.repetitionsSet8 +
          resultData.repetitionsSet9 +
          resultData.repetitionsSet10
        } reps ${weight}`;
      }
    };

    const findResult = (exercise: IWorkoutExercise | null) =>
      results.value.find(
        (r) =>
          r.workoutId === props.workoutProp.id &&
          r.trainingExerciseId === exercise?.trainingExerciseId
      ) ?? null;

    return {
      workoutExercises,
      displayResultForm,
      workoutExercise,
      result,
      toggleResultForm,
      handleFormSubmission,
      getDateFormatted,
      getResults,
    };
  },
});
