import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import { setupHttpInterceptors } from '@/@config/http-interceptor';
import router from '@/@router';
import configureIcons from './@config/fontawesome-config';

// Font Awesome icons
configureIcons();

// Http configuration
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
setupHttpInterceptors();

// App configuration
const app = createApp(App);
app.use(router).mount('#app');

console.log(process.env.NODE_ENV);
