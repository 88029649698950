import axios from 'axios';
import { ITraining } from '@/training/@interfaces/ITraining';

export const trainingService = {
  createData,
  getById,
  getAllData,
  updateData,
  deleteData,
};

async function createData(training: ITraining): Promise<ITraining> {
  const response = await axios.post('/training', training);
  return response.data;
}

async function getById(id: number): Promise<ITraining> {
  const response = await axios.get<ITraining>(`/training/${id}`);
  return response.data;
}

async function getAllData(offset: number, limit: number): Promise<ITraining[]> {
  const response = await axios.get<ITraining[]>(
    `/training?offset=${offset}&limit=${limit}`
  );
  return response.data;
}

async function updateData(id: number, training: ITraining): Promise<void> {
  await axios.put(`/training/${id}`, training);
}

async function deleteData(id: number): Promise<void> {
  await axios.delete(`/training/${id}`);
}
