
import { defineComponent, PropType } from '@vue/runtime-core';
import { onMounted, ref } from 'vue';
import { ITrainingExercise } from '@/training/@interfaces/ITrainingExercise';
import { ITraining } from '@/training/@interfaces/ITraining';
import { IExercise } from '@/exercise/@interfaces/IExercise';

export default defineComponent({
  name: 'TrainingExerciseForm',
  props: {
    trainingProp: {
      type: Object as PropType<ITraining>,
      required: true,
    },
    exercises: {
      type: Object as PropType<IExercise[]>,
      required: true,
    },
    exercise: {
      type: Object as PropType<ITrainingExercise | null>,
      required: false,
    },
  },
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const trainingExercise = ref<ITrainingExercise>({
      trainingId: props.trainingProp.id,
    } as ITrainingExercise);
    const initializeData = () => {
      if (props.exercise) {
        trainingExercise.value = props.exercise;
      }
    };
    const onSubmit = () => {
      emit('formSubmitted', trainingExercise.value);
    };
    onMounted(initializeData);
    return {
      trainingExercise,
      onSubmit,
    };
  },
});
