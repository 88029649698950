
import { defineComponent, PropType, ref, onMounted } from 'vue';
import { IHealth } from '@/health/@interfaces/IHealth';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import useHealthStore from './@composables/useHealthStore';

export default defineComponent({
  name: 'HealthForm',
  components: { FontAwesomeIcon },
  props: {
    healthProp: {
      type: Object as PropType<IHealth | null>,
      required: false,
    },
  },
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const health = ref<IHealth>({} as IHealth);
    const displayBodyFractions = ref(false);
    const displayBodyMeasurements = ref(false);
    const displayHeight = ref(false);
    const healthStore = useHealthStore();
    const initialiseData = async () => {
      if (props.healthProp) {
        health.value = props.healthProp;
        health.value.date = health.value.date.substring(0, 10);
        displayHeight.value = true;
        displayBodyFractions.value = true;
        displayBodyMeasurements.value = true;
      } else {
        health.value = {} as IHealth;
        const lastData = healthStore.lastHealthData;
        if (lastData.value) {
          health.value.height = lastData.value.height;
        } else {
          displayHeight.value = true;
        }
      }
    };
    const setDisplayBodyFractions = () => {
      displayBodyFractions.value = true;
    };
    const setDisplayBodyMeasurements = () => {
      displayBodyMeasurements.value = true;
    };
    const setDisplayHeight = () => {
      displayHeight.value = true;
    };
    onMounted(initialiseData);
    const onSubmit = async () => {
      if (!props.healthProp) {
        await healthStore.createData(health.value);
        emit('formSubmitted');
      } else {
        await healthStore.updateData(health.value.id, health.value);
        emit('formSubmitted', health.value);
      }
    };
    return {
      health,
      displayBodyFractions,
      displayBodyMeasurements,
      displayHeight,
      onSubmit,
      setDisplayBodyFractions,
      setDisplayBodyMeasurements,
      setDisplayHeight,
    };
  },
});
