import axios, { AxiosError } from 'axios';
import { authService } from '@/@shared/@services/auth.service';

let firstAttempt = true;

export function setupHttpInterceptors(): void {
  axios.interceptors.request.use(function (config) {
    const token = authService.getAccessToken();
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  axios.interceptors.response.use(
    function (response) {
      firstAttempt = true;
      return response;
    },
    async function (error: AxiosError) {
      if (error.config.url !== 'user/authenticate' && error.response) {
        if (error.response.status === 401 && firstAttempt) {
          firstAttempt = false;
          await authService.refreshToken();
          const token = authService.getAccessToken();
          error.config.headers['Authorization'] = `Bearer ${token}`;
          return axios(error.config);
        } else if (error.response.status === 401 && !firstAttempt) {
          firstAttempt = true;
          await authService.logout();
        }
      }
      return Promise.reject(error.response?.data);
    }
  );
}
