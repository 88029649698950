
import { defineComponent } from '@vue/runtime-core';
import { ref } from 'vue';
import TrainingForm from '@/training/TrainingForm.vue';
import { ITraining } from '@/training/@interfaces/ITraining';
import TrainingExercisesForm from '@/training/TrainingExercisesForm.vue';

export default defineComponent({
  name: 'TrainingCreationView',
  components: {
    TrainingExercisesForm,
    TrainingForm,
  },
  setup() {
    const training = ref<ITraining>();
    const assignTrainingName = (event: ITraining) => {
      training.value = event;
    };
    return {
      training,
      assignTrainingName,
    };
  },
});
