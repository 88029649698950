import axios from 'axios';
import { IExercise } from '@/exercise/@interfaces/IExercise';

export const exerciseService = {
  getAllData,
  createData,
  deleteData,
  updateData,
};

async function getAllData(offset: number, limit: number): Promise<IExercise[]> {
  const response = await axios.get<IExercise[]>(
    `/exercise?offset=${offset}&limit=${limit}`
  );
  return response.data;
}

async function createData(exercise: IExercise): Promise<IExercise> {
  const response = await axios.post<IExercise>('/exercise', exercise);
  return response.data;
}

async function deleteData(id: number): Promise<void> {
  await axios.delete(`/exercise/${id}`);
}

async function updateData(id: number, exercise: IExercise): Promise<void> {
  await axios.put(`/exercise/${id}`, exercise);
}
