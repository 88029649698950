
import { defineComponent, PropType } from '@vue/runtime-core';
import { ref } from 'vue';
import TrainingExerciseForm from './TrainingExerciseForm.vue';
import draggable from 'vuedraggable';
import router from '@/@router';
import { ITrainingExercise } from '@/training/@interfaces/ITrainingExercise';
import { ITraining } from '@/training/@interfaces/ITraining';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import HomeView from '@/home/HomeView.vue';
import useTrainingExercisesHandling from '@/training/@composables/useTrainingExercisesHandling';
import useExerciseStore from '@/exercise/@composables/useExerciseStore';

export default defineComponent({
  name: 'TrainingExercisesForm',
  components: { TrainingExerciseForm, draggable, FontAwesomeIcon },
  props: {
    trainingProp: {
      type: Object as PropType<ITraining>,
      required: true,
    },
  },
  setup(props) {
    const nameSubmitted = ref(false);
    const { exercises } = useExerciseStore();
    const trainingExerciseHandling = useTrainingExercisesHandling(
      props.trainingProp.id
    );
    const canModifyExercise = trainingExerciseHandling.canModifyExercise;
    const canAddExercise = trainingExerciseHandling.canAddExercise;
    const trainingExercises = trainingExerciseHandling.trainingExercises;
    const exerciseSelected = trainingExerciseHandling.exerciseSelected;

    const closeExerciseForm = () => {
      canAddExercise.value = false;
      canModifyExercise.value = false;
    };

    const getExerciseName = (id: number): string => {
      return exercises.value.find((item) => item.id === id)?.name ?? '/';
    };

    const addTrainingExercise = async (event: ITrainingExercise) => {
      event.trainingId = props.trainingProp.id;
      event.exercisePosition = trainingExercises.value.length + 1 || 1;
      await trainingExerciseHandling.createExercise(event);
      canAddExercise.value = false;
    };

    const updateTrainingExercise = async (event: ITrainingExercise) => {
      await trainingExerciseHandling.updateExercise(event.id, event);
      canModifyExercise.value = false;
    };

    const onSubmit = async () => {
      await router.push(HomeView);
    };

    return {
      nameSubmitted,
      canModifyExercise,
      canAddExercise,
      trainingExercises,
      exercises,
      exerciseSelected,
      trainingExerciseHandling,
      closeExerciseForm,
      addTrainingExercise,
      updateTrainingExercise,
      getExerciseName,
      onSubmit,
    };
  },
});
