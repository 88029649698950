
import { ITraining } from '@/training/@interfaces/ITraining';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineComponent, ref } from 'vue';
import WorkoutSelected from './WorkoutSelected.vue';
import WorkoutForm from './WorkoutForm.vue';
import { IWorkout } from './@interfaces/IWorkout';

export default defineComponent({
  name: 'WorkoutView',
  components: { FontAwesomeIcon, WorkoutSelected, WorkoutForm },
  setup() {
    const formSubmitted = ref(false);
    const trainingSelected = ref<ITraining | null>(null);
    const workoutSelected = ref<IWorkout | null>(null);
    const changeSelection = () => {
      formSubmitted.value = false;
      trainingSelected.value = null;
    };
    const handleFormSubmission = ($event: {
      workoutValue: IWorkout; // the name must be the same as the emitted event
      trainingSelected: ITraining; // the name must be the same as the emitted event
    }) => {
      formSubmitted.value = true;
      trainingSelected.value = $event.trainingSelected;
      workoutSelected.value = $event.workoutValue;
    };
    return {
      trainingSelected,
      formSubmitted,
      workoutSelected,
      changeSelection,
      handleFormSubmission,
    };
  },
});
