
import { computed, defineComponent } from 'vue';
import router from '@/@router';
import useTrainingStore from './@composables/useTrainingStore';
import { ITraining } from './@interfaces/ITraining';
import TrainingResultsChart from './TrainingResultsChart.vue';

export default defineComponent({
  name: 'TrainingResultsView',
  components: { TrainingResultsChart },
  setup() {
    const trainingStore = useTrainingStore();
    const training = computed<ITraining>(
      () =>
        trainingStore.getById(+router.currentRoute.value.params.trainingId) ??
        ({} as ITraining)
    );

    return { training };
  },
});
