
import { defineComponent, PropType, ref, watch } from 'vue';
import useResultChartData from './@composables/useResultChartData';
import { ITraining } from './@interfaces/ITraining';
import { Chart, registerables, ChartData } from 'chart.js';
import useResultChartOptions from './@composables/useResultChartOptions';
import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';
import { Line } from 'vue-chartjs';

export default defineComponent({
  name: 'TrainingResultsChart',
  components: { Line },
  props: {
    trainingProp: {
      type: Object as PropType<ITraining>,
      required: true,
    },
  },
  setup(props) {
    Chart.register(...registerables);

    const { options } = useResultChartOptions();
    const { dates, datasets } = useResultChartData(props.trainingProp);
    const chartData = ref<ChartData<'line'> | null>(null);
    const isMobile = userAgentHelper.isMobile();

    const getChartData = () => {
      if (
        !(dates.value && datasets.value) ||
        datasets.value.find((x) => x.label === '?') // don't initialize chart until we get all exercises results
      ) {
        return;
      }
      chartData.value = { labels: dates.value, datasets: datasets.value };
    };

    watch(
      () => [dates.value, datasets.value],
      () => getChartData()
    );

    return {
      chartData,
      options,
      isMobile,
    };
  },
});
