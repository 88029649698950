
import useTrainingStore from '@/training/@composables/useTrainingStore';
import { defineComponent, ref } from 'vue';
import useWorkoutStore from './@composables/useWorkoutStore';
import { IWorkout } from './@interfaces/IWorkout';

export default defineComponent({
  name: 'WorkoutForm',
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const workout = ref<IWorkout>({} as IWorkout);
    const { trainings } = useTrainingStore();
    const workoutStore = useWorkoutStore();

    const onSubmit = async () => {
      const workoutValue = await workoutStore.createData(workout.value);
      const trainingSelected = trainings.value.find(
        (training) => training.id === workoutValue.trainingId
      );
      const event = {
        workoutValue,
        trainingSelected,
      };
      emit('formSubmitted', event);
    };
    return {
      workout,
      trainings,
      onSubmit,
    };
  },
});
