import { transformDateToNumericLocaleString } from '@/@shared/@helpers/date-helper';
import { computed } from 'vue';
import useHealthStore from './useHealthStore';

export default function useHealthChartData() {
  const data = useHealthStore().healthList;
  const sortedData = computed(() =>
    [...data.value].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
  );

  const dates = computed(() =>
    sortedData.value.map((x) => transformDateToNumericLocaleString(x.date))
  );

  const heightData = computed(() =>
    sortedData.value.map((x) => x.height || null)
  );

  const weightData = computed(() =>
    sortedData.value.map((x) => x.weight || null)
  );

  const fatData = computed(() =>
    sortedData.value.map((x) => x.fatFraction * 100 || null)
  );

  const muscleData = computed(() =>
    sortedData.value.map((x) => x.muscleFraction * 100 || null)
  );

  const waterData = computed(() =>
    sortedData.value.map((x) => x.waterFraction * 100 || null)
  );

  const neckData = computed(() => sortedData.value.map((x) => x.neck || null));

  const shouldersData = computed(() =>
    sortedData.value.map((x) => x.shoulders || null)
  );

  const chestData = computed(() =>
    sortedData.value.map((x) => x.chest || null)
  );

  const waistData = computed(() =>
    sortedData.value.map((x) => x.waist || null)
  );

  const forearmData = computed(() =>
    sortedData.value.map((x) => x.forearm || null)
  );

  const thighData = computed(() =>
    sortedData.value.map((x) => x.thigh || null)
  );

  const calfData = computed(() => sortedData.value.map((x) => x.calf || null));

  const bicepsData = computed(() =>
    sortedData.value.map((x) => x.biceps || null)
  );

  const wristData = computed(() =>
    sortedData.value.map((x) => x.wrist || null)
  );

  return {
    dates,
    heightData,
    weightData,
    fatData,
    muscleData,
    waterData,
    neckData,
    shouldersData,
    chestData,
    waistData,
    forearmData,
    thighData,
    calfData,
    bicepsData,
    wristData,
  };
}
