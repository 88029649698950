
import { defineComponent, onMounted, PropType } from '@vue/runtime-core';
import { ref } from 'vue';
import { ITraining } from '@/training/@interfaces/ITraining';
import useTrainingStore from './@composables/useTrainingStore';

export default defineComponent({
  name: 'TrainingForm',
  props: {
    trainingProp: {
      type: Object as PropType<ITraining>,
      required: false,
    },
  },
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const training = ref<ITraining>({} as ITraining);
    const trainingStore = useTrainingStore();
    const onSubmit = async () => {
      if (training.value.name.length >= 1) {
        if (props.trainingProp) {
          await trainingStore.updateData(props.trainingProp.id, training.value);
          emit('formSubmitted', training.value);
        } else {
          const newTraining = await trainingStore.createData(training.value);
          emit('formSubmitted', newTraining);
        }
      }
    };
    const initializeData = () => {
      if (props.trainingProp) {
        training.value = props.trainingProp;
      }
    };
    onMounted(initializeData);
    return {
      training,
      onSubmit,
    };
  },
});
