import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';
import { ChartOptions, ChartDataset } from 'chart.js';

export default function useHealthChartOptions() {
  const options: ChartOptions = {
    responsive: true,
    scales: {
      y1: {
        beginAtZero: false,
        position: 'left',
        title: { text: 'Mensurations (cm ou kg)', display: true },
        grid: { display: true },
      },
      y2: {
        beginAtZero: true,
        position: 'right',
        title: { text: '% physiologiques', display: true },
        grid: { display: false },
      },
    },
    plugins: {
      title: { text: 'Évolution des données de santé', display: true },
      legend: { position: 'bottom', display: !userAgentHelper.isMobile() },
    },
  };

  const datasetsOptionsLine: Partial<ChartDataset> = {
    type: 'line',
    yAxisID: 'y1',
    spanGaps: true,
    pointRadius: userAgentHelper.isMobile() ? 0 : 3,
  };

  const datasetsOptionsBar: Partial<ChartDataset> = {
    type: 'bar',
    yAxisID: 'y2',
  };

  return { options, datasetsOptionsLine, datasetsOptionsBar };
}
