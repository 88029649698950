import { computed, onMounted, ref } from 'vue';
import { ITraining } from '../@interfaces/ITraining';
import { trainingService } from '../@services/training.service';
import messages from '@/assets/text/messages.json';

const trainings = ref<ITraining[]>([] as ITraining[]);

export default function useTrainingStore() {
  const getAllData = async () => {
    trainings.value = await trainingService.getAllData(0, 1000);
  };

  const getById = (id: number) => {
    return trainings.value.find((t) => t.id === id) ?? null;
  };

  const deleteData = async (id: number) => {
    if (!confirm(messages.confirmation.delete)) {
      return;
    }
    await trainingService.deleteData(id);
    await getAllData();
  };

  const createData = async (data: ITraining) => {
    const training = await trainingService.createData(data);
    await getAllData();
    return training;
  };

  const updateData = async (id: number, data: ITraining) => {
    await trainingService.updateData(id, data);
    await getAllData();
  };

  const getSortedTrainings = () => {
    return [...trainings.value].sort((a, b) => a.name.localeCompare(b.name));
  };

  onMounted(async () => {
    if (!trainings.value.length) {
      await getAllData();
    }
  });

  return {
    trainings: computed(() => getSortedTrainings()),
    getById,
    deleteData,
    createData,
    updateData,
  };
}
