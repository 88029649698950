import { computed, onMounted, ref } from 'vue';
import { IExercise } from '../@interfaces/IExercise';
import { exerciseService } from '../@services/exercise.service';
import messages from '@/assets/text/messages.json';

const exercises = ref<IExercise[]>([] as IExercise[]);

export default function useExerciseStore() {
  const getAllData = async () => {
    exercises.value = await exerciseService.getAllData(0, 1000);
  };

  const deleteData = async (id: number) => {
    if (!confirm(messages.confirmation.delete)) {
      return;
    }
    await exerciseService.deleteData(id);
    await getAllData();
  };

  const createData = async (data: IExercise) => {
    const exercise = await exerciseService.createData(data);
    await getAllData();
    return exercise;
  };

  const updateData = async (id: number, data: IExercise) => {
    await exerciseService.updateData(id, data);
    await getAllData();
  };

  const getSortedExercises = () => {
    return [...exercises.value].sort((a, b) => a.name.localeCompare(b.name));
  };

  onMounted(async () => {
    if (!exercises.value.length) {
      await getAllData();
    }
  });

  return {
    exercises: computed(() => getSortedExercises()),
    deleteData,
    createData,
    updateData,
  };
}
