
import { defineComponent, PropType, ref } from 'vue';
import { IHealth } from '@/health/@interfaces/IHealth';
import { transformDateToNumericLocaleString } from '@/@shared/@helpers/date-helper';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';
import useHealthStore from './@composables/useHealthStore';
import PaginateComponent from '@/@shared/PaginateComponent.vue';

export default defineComponent({
  name: 'HealthDataTable',
  components: { FontAwesomeIcon, PaginateComponent },
  props: {
    healthListProp: {
      type: Object as PropType<IHealth[]>,
      required: true,
    },
  },
  emits: ['updateAsked', 'deleteAsked'],
  setup(props, { emit }) {
    const isMobile = () => userAgentHelper.isMobile();
    const healthStore = useHealthStore();
    const paginatedHealthData = ref<IHealth[]>([]);

    const removeRow = async (row: IHealth) => {
      await healthStore.deleteData(row.id);
      emit('deleteAsked', row);
    };
    const getDateFormatted = (date: Date | string) => {
      return transformDateToNumericLocaleString(new Date(date));
    };
    const updateRow = (row: IHealth) => {
      emit('updateAsked', row);
    };

    const onPagination = (event: unknown[]) => {
      paginatedHealthData.value = event as IHealth[];
    };
    return {
      paginatedHealthData,
      removeRow,
      getDateFormatted,
      updateRow,
      isMobile,
      onPagination,
    };
  },
});
