import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faTimes,
  faEdit,
  faEye,
  faTrashAlt,
  faStopwatch,
  faDumbbell,
  faFolderPlus,
  faFolder,
  faMinus,
  faBars,
  faHome,
  faSignOutAlt,
  faArrowAltCircleLeft,
  faTrophy,
  faChevronRight,
  faUser,
  faUserPlus,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle,
  faPlayCircle,
} from '@fortawesome/free-regular-svg-icons';

export default function configureIcons() {
  library.add(faPlus);
  library.add(faTimes);
  library.add(faEdit);
  library.add(faEye);
  library.add(faTrashAlt);
  library.add(faStopwatch);
  library.add(faDumbbell);
  library.add(faFolderPlus);
  library.add(faFolder);
  library.add(faMinus);
  library.add(faBars);
  library.add(faHome);
  library.add(faSignOutAlt);
  library.add(faCheckCircle);
  library.add(faPlayCircle);
  library.add(faArrowAltCircleLeft);
  library.add(faTrophy);
  library.add(faChevronRight);
  library.add(faUser);
  library.add(faUserPlus);
  library.add(faAngleLeft);
  library.add(faAngleRight);
  library.add(faAngleDoubleLeft);
  library.add(faAngleDoubleRight);
}
