import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';
import { ChartOptions } from 'chart.js';

export default function useResultChartOptions() {
  const options: ChartOptions = {
    responsive: true,
    scales: {
      y1: {
        beginAtZero: true,
        position: 'left',
        title: { text: 'Répétitions x Poids', display: true },
        grid: { display: true },
      },
      y2: {
        beginAtZero: true,
        position: 'right',
        title: { text: 'Temps (s)', display: true },
        grid: { display: false },
      },
    },
    plugins: {
      title: { text: 'Évolution des performances', display: true },
      legend: { position: 'bottom', display: !userAgentHelper.isMobile() },
    },
  };

  return { options };
}
