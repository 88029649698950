/**
 * Returns the IMC
 * @param height in cm
 * @param weight in kg
 */
export default function calculateIMC(
  height: number,
  weight: number
): number | null {
  if (height == 0) return null;
  return Math.round((10000 * weight) / height ** 2);
}
