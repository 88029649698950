
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, defineComponent } from 'vue';
import { authService } from './@services/auth.service';

export default defineComponent({
  name: 'HeaderComponent',
  components: { FontAwesomeIcon },
  setup() {
    const role = computed(() => authService.getUserRole());
    return { role };
  },
});
