import axios from 'axios';
import { IHealth } from '@/health/@interfaces/IHealth';

export const healthService = {
  getLastData,
  createData,
  getAllData,
  deleteData,
  updateData,
};

async function getLastData(): Promise<IHealth | null> {
  const response = await axios.get<IHealth>('/health/last');
  if (response.status === 204) return null;
  return response.data;
}

async function createData(health: IHealth): Promise<IHealth> {
  const response = await axios.post<IHealth>('/health', health);
  return response.data;
}

async function getAllData(offset: number, limit: number): Promise<IHealth[]> {
  const response = await axios.get<IHealth[]>(
    `/health?offset=${offset}&limit=${limit}`
  );
  return response.data;
}

async function deleteData(id: number): Promise<void> {
  await axios.delete(`/health/${id}`);
}

async function updateData(id: number, health: IHealth): Promise<void> {
  await axios.put(`/health/${id}`, health);
}
