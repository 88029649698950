import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/login/LoginView.vue';
import ExercisesView from '@/exercise/ExercisesView.vue';
import HealthView from '@/health/HealthView.vue';
import HomeView from '@/home/HomeView.vue';
import TrainingCreationView from '@/training/TrainingCreationView.vue';
import TrainingsView from '@/training/TrainingsView.vue';
import WorkoutView from '@/workout/WorkoutView.vue';
import ResultsView from '@/workout/ResultsView.vue';
import TrainingResultsView from '@/training/TrainingResultsView.vue';
import * as tokenValidity from '@/@shared/@helpers/token-validity';
import { authService } from '@/@shared/@services/auth.service';
import ProfileView from '@/user/ProfileView.vue';
import AddUserView from '@/user/AddUserView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
  },
  {
    path: '/health',
    name: 'HealthView',
    component: HealthView,
  },
  {
    path: '/exercises',
    name: 'ExercisesView',
    component: ExercisesView,
  },
  {
    path: '/training-creation',
    name: 'TrainingCreationView',
    component: TrainingCreationView,
  },
  {
    path: '/trainings',
    name: 'TrainingsView',
    component: TrainingsView,
  },
  {
    path: '/workout',
    name: 'WorkoutView',
    component: WorkoutView,
  },
  {
    path: '/results',
    name: 'ResultsView',
    component: ResultsView,
  },
  {
    path: '/training-results/:trainingId',
    name: 'TrainingResultsView',
    component: TrainingResultsView,
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView,
  },
  {
    path: '/add-user',
    name: 'AddUserView',
    component: AddUserView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = tokenValidity.isTokenValid();
  if (to.name !== 'LoginView' && !isAuthenticated) {
    try {
      await authService.refreshToken();
      isAuthenticated = tokenValidity.isTokenValid();
      if (isAuthenticated) {
        next();
      } else {
        next({ name: 'LoginView' });
      }
    } catch (error) {
      next({ name: 'LoginView' });
    }
  } else {
    next();
  }
});

export default router;
