
import { defineComponent, onMounted } from 'vue';
import router from '@/@router';
import { authService } from '@/@shared/@services/auth.service';
import LoginForm from '@/login/LoginForm.vue';

export default defineComponent({
  name: 'LoginView',
  components: { LoginForm },
  setup() {
    const navigateToHome = async () => router.push('/');
    onMounted(() => authService.logout());
    return { navigateToHome };
  },
});
