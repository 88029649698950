const COLORS = {
  red: 'rgb(255, 99, 132)',
  darkRed: 'rgb(124,10,2)',
  orange: 'rgb(255, 159, 64)',
  darkOrange: 'rgb(204,85,0)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  darkGreen: 'rgb(0,106,78)',
  blue: 'rgb(54, 162, 235)',
  darkBlue: 'rgb(0,48,143)',
  purple: 'rgb(153, 102, 255)',
  darkPurple: 'rgb(91,50,86)',
  grey: 'rgb(201, 203, 207)',
  darkGrey: 'rgb(85,85,85)',
};

const BACKGROUND_COLORS = {
  red: 'rgb(255, 99, 135, 0.5)',
  darkRed: 'rgb(124,10,2, 0.5)',
  orange: 'rgb(255, 159, 64, 0.5)',
  darkOrange: 'rgb(204,85,0, 0.5)',
  yellow: 'rgb(255, 205, 86, 0.5)',
  green: 'rgb(75, 192, 192, 0.5)',
  darkGreen: 'rgb(0,106,78, 0.5)',
  blue: 'rgb(54, 162, 235, 0.5)',
  darkBlue: 'rgb(0,48,143, 0.5)',
  purple: 'rgb(153, 102, 255, 0.5)',
  darkPurple: 'rgb(91,50,86, 0.5)',
  grey: 'rgb(201, 203, 207, 0.5)',
  darkGrey: 'rgb(85,85,85, 0.5)',
};

const getColorByKeyOrDefault = (id: number) => {
  const colorEntries = Object.entries(COLORS);
  if (id >= colorEntries.length) {
    return colorEntries[(id - colorEntries.length) % colorEntries.length][1];
  }
  return colorEntries[id][1];
};

const getBackgroundColorByKeyOrDefault = (id: number) => {
  const backgroundColorEntries = Object.entries(BACKGROUND_COLORS);
  if (id >= backgroundColorEntries.length) {
    return backgroundColorEntries[
      (id - backgroundColorEntries.length) % backgroundColorEntries.length
    ][1];
  }
  return backgroundColorEntries[id][1];
};

export const chartHelper = {
  COLORS,
  BACKGROUND_COLORS,
  getColorByKeyOrDefault,
  getBackgroundColorByKeyOrDefault,
};
