import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dccf854e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Line, {
      "chart-data": _ctx.chartData,
      "chart-options": _ctx.options,
      height: 200
    }, null, 8, ["chart-data", "chart-options"])
  ]))
}