
import { defineComponent, onMounted, ref, watch } from 'vue';
import router from '@/@router';
import HeaderComponent from '@/@shared/HeaderComponent.vue';
import FooterComponent from '@/@shared/FooterComponent.vue';

export default defineComponent({
  name: 'App',
  components: { FooterComponent, HeaderComponent },
  setup() {
    let displayHeader = ref(false);
    const checkRoute = () =>
      (displayHeader.value = router.currentRoute.value.name !== 'LoginView');
    watch(() => router.currentRoute.value.name, checkRoute);
    onMounted(checkRoute);
    return { displayHeader };
  },
});
