import { IWorkout } from '@/workout/@interfaces/IWorkout';
import axios from 'axios';

export const workoutService = {
  getById,
  getAll,
  createData,
  deleteData,
  updateData,
};

async function getById(id: number): Promise<IWorkout | null> {
  const response = await axios.get<IWorkout>(`/workout/${id}`);
  if (response.status === 204) return null;
  return response.data;
}

async function getAll(offset: number, limit: number): Promise<IWorkout[]> {
  const response = await axios.get<IWorkout[]>(
    `/workout?offset=${offset}&limit=${limit}`
  );
  return response.data;
}

async function createData(result: IWorkout): Promise<IWorkout> {
  const response = await axios.post<IWorkout>('/workout', result);
  return response.data;
}

async function deleteData(id: number): Promise<void> {
  await axios.delete(`/workout/${id}`);
}

async function updateData(id: number, result: IWorkout): Promise<void> {
  await axios.put(`/workout/${id}`, result);
}
