
import { defineComponent, onMounted, PropType } from '@vue/runtime-core';
import { ref } from 'vue';
import { IExercise } from '@/exercise/@interfaces/IExercise';
import useExerciseStore from './@composables/useExerciseStore';

export default defineComponent({
  name: 'ExerciseForm',
  props: {
    exerciseProp: {
      type: Object as PropType<IExercise | null>,
      required: false,
    },
  },
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const exercise = ref<IExercise>({} as IExercise);
    const exerciseStore = useExerciseStore();
    const initialiseData = () => {
      exercise.value = props.exerciseProp ?? ({} as IExercise);
    };
    const onSubmit = async () => {
      if (!props.exerciseProp) {
        await exerciseStore.createData(exercise.value);
      } else {
        await exerciseStore.updateData(exercise.value.id, exercise.value);
      }
      emit('formSubmitted');
    };
    onMounted(initialiseData);
    return {
      exercise,
      onSubmit,
    };
  },
});
