import { computed, onMounted, ref } from 'vue';
import { IResult } from '@/workout/@interfaces/IResult';
import { resultService } from '@/workout/@services/result.service';
import { ITraining } from '../@interfaces/ITraining';

export default function useResultStore(training: ITraining) {
  const results = ref<IResult[]>([] as IResult[]);

  const getAllData = async () => {
    results.value = await resultService.getByTraining(training.id);
  };

  const getById = (id: number) => {
    return results.value.find((result) => result.id === id) ?? null;
  };

  const deleteData = async (id: number) => {
    await resultService.deleteData(id);
    await getAllData();
  };

  const createData = async (data: IResult) => {
    const result = await resultService.createData(data);
    await getAllData();
    return result;
  };

  const updateData = async (id: number, data: IResult) => {
    await resultService.updateData(id, data);
    await getAllData();
  };

  onMounted(async () => {
    await getAllData();
  });

  return {
    results: computed(() => results.value),
    deleteData,
    createData,
    updateData,
    getById,
  };
}
