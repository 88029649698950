export { transformDateToLocaleString, transformDateToNumericLocaleString };

function transformDateToLocaleString(date: Date | string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('fr-FR', options);
  } else {
    return date.toLocaleDateString('fr-FR', options);
  }
}

function transformDateToNumericLocaleString(date: Date | string): string {
  if (!date) return '';
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('fr-FR', options);
  } else {
    return date.toLocaleDateString('fr-FR', options);
  }
}
