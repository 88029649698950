import { ITrainingExercise } from '@/training/@interfaces/ITrainingExercise';
import { ref } from 'vue';
import useTrainingExerciseStore from './useTrainingExerciseStore';

export default function useTrainingExercisesHandling(trainingId: number) {
  const trainingExerciseStore = useTrainingExerciseStore(trainingId);
  const trainingExercises = trainingExerciseStore.trainingExercises;
  const exerciseSelected = ref<ITrainingExercise | null>(null);
  const canModifyExercise = ref(false);
  const canAddExercise = ref(false);

  const removeExercise = async (index: number, element: ITrainingExercise) => {
    await trainingExerciseStore.deleteData(element.id);
    updateTrainingOrder();
  };

  const changeExercise = (index: number, element: ITrainingExercise) => {
    exerciseSelected.value = element;
    canModifyExercise.value = true;
  };

  const addExercise = () => (canAddExercise.value = true);

  const updateTrainingOrder = () => {
    trainingExercises.value.forEach(async (item) => {
      item.exercisePosition = trainingExercises.value.indexOf(item) + 1;
      await trainingExerciseStore.updateData(item.id, item);
    });
  };

  const createExercise = async (data: ITrainingExercise) => {
    await trainingExerciseStore.createData(data);
  };

  const updateExercise = async (id: number, data: ITrainingExercise) => {
    await trainingExerciseStore.updateData(id, data);
  };

  return {
    trainingExercises,
    exerciseSelected,
    canModifyExercise,
    canAddExercise,
    removeExercise,
    changeExercise,
    addExercise,
    updateTrainingOrder,
    createExercise,
    updateExercise,
  };
}
