import { IUser } from '@/@shared/@interfaces/IUser';
import { authService } from '@/@shared/@services/auth.service';
import axios from 'axios';
import { IUpdateUser } from '../@interfaces/IUpdateUser';

export const userService = { update, deleteAccount, getStoredUser, create };

function getStoredUser(): IUser {
  const storedUser = localStorage.getItem('user');
  let userObject = {} as IUser;
  if (storedUser) {
    userObject = JSON.parse(storedUser);
  }
  return userObject;
}

async function update(id: number, user: IUpdateUser): Promise<void> {
  await axios.put(`/user/${id}`, user);
  await authService.refreshToken();
}

async function deleteAccount(id: number): Promise<void> {
  await axios.delete(`/user/${id}`);
  await authService.logout();
}

async function create(user: IUpdateUser): Promise<void> {
  const response = await axios.post('/user/register', user);
  return response.data;
}
