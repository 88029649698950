import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "training-results-view" }
const _hoisted_2 = { class: "nav-secondary" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_training_results_chart = _resolveComponent("training-results-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$router.back && _ctx.$router.back(...args)))
      }, "Retour")
    ]),
    (_ctx.training.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_training_results_chart, { "training-prop": _ctx.training }, null, 8, ["training-prop"])
        ]))
      : _createCommentVNode("", true)
  ]))
}