
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import useResultStore from './@composables/useResultStore';
import { IResult } from './@interfaces/IResult';
import { IWorkout } from './@interfaces/IWorkout';
import { IWorkoutExercise } from './@interfaces/IWorkoutExercise';

export default defineComponent({
  name: 'ResultForm',
  components: { FontAwesomeIcon },
  props: {
    workoutExercise: {
      type: Object as PropType<IWorkoutExercise>,
      required: true,
    },
    workout: {
      type: Object as PropType<IWorkout>,
      required: true,
    },
    resultProp: {
      type: Object as PropType<IResult | null>,
      required: false,
    },
  },
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const result = ref<IResult>({
      trainingExerciseId: props.workoutExercise.trainingExerciseId,
      workoutId: props.workout.id,
    } as IResult);
    const seriesX = ref(0);
    const resultStore = useResultStore(props.workout);
    const seriesBtn = ref<HTMLButtonElement>();
    const previousResult = computed(() => findPreviousResults());

    const onSubmit = async () => {
      if (props.resultProp) {
        await resultStore.updateData(result.value.id, result.value);
      } else {
        await resultStore.createData(result.value);
      }
      emit('formSubmitted');
    };

    const initializeData = () => {
      if (props.resultProp) {
        result.value = props.resultProp;
        seriesX.value = 10;
      }
    };

    const incrementSeries = () => {
      seriesX.value += 1;
    };

    const findPreviousResults = () => {
      return (
        resultStore.previousResults.value.find(
          (r) =>
            r.trainingExerciseId === props.workoutExercise.trainingExerciseId
        ) ?? null
      );
    };

    onMounted(() => {
      initializeData();
      if (!props.workoutExercise.isTimedExercise) {
        seriesBtn.value?.click(); // pour éviter d'avoir à cliquer 2 fois sur le bouton la première fois...
      }
    });

    return {
      result,
      previousResult,
      seriesX,
      seriesBtn,
      onSubmit,
      incrementSeries,
    };
  },
});
