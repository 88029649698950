
import { defineComponent } from '@vue/runtime-core';
import ExerciseForm from '@/exercise/ExerciseForm.vue';
import useFormToggler from '@/@shared/@composables/useFormToggler';
import { IExercise } from '@/exercise/@interfaces/IExercise';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';
import { ref } from 'vue';
import useExerciseStore from './@composables/useExerciseStore';
import PaginateComponent from '@/@shared/PaginateComponent.vue';

export default defineComponent({
  name: 'ExercisesView',
  components: { ExerciseForm, FontAwesomeIcon, PaginateComponent },
  setup() {
    const exerciseSelected = ref<IExercise | null>(null);
    const { displayForm, toggleForm } = useFormToggler();
    const isMobile = () => userAgentHelper.isMobile();
    const exerciseStore = useExerciseStore();

    const paginatedExercises = ref<IExercise[]>([]);

    const updateRow = (row: IExercise) => {
      exerciseSelected.value = row;
      toggleForm();
    };
    const createRow = () => {
      exerciseSelected.value = null;
      toggleForm();
    };

    const deleteData = async (id: number) => exerciseStore.deleteData(id);

    const onPagination = (event: unknown[]) => {
      paginatedExercises.value = event as IExercise[];
    };

    return {
      exercises: exerciseStore.exercises,
      displayForm,
      exerciseSelected,
      paginatedExercises,
      toggleForm,
      deleteData,
      updateRow,
      createRow,
      isMobile,
      onPagination,
    };
  },
});
