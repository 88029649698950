import jwtDecode, { JwtPayload } from 'jwt-decode';
import { authService } from '@/@shared/@services/auth.service';

export function isTokenValid(): boolean {
  const token = authService.getAccessToken();
  if (token) {
    const decoded = jwtDecode<JwtPayload>(token);
    const currentTime = new Date().getTime() / 1000;
    if (decoded.exp && decoded.exp >= currentTime) {
      return true;
    }
  }
  return false;
}
