import { computed, onMounted, ref } from 'vue';
import { ITrainingExercise } from '../@interfaces/ITrainingExercise';
import { trainingExerciseService } from '../@services/training-exercise.service';

export default function useTrainingExerciseStore(trainingId: number) {
  const trainingExercises = ref<ITrainingExercise[]>([] as ITrainingExercise[]);

  const getAllData = async () => {
    trainingExercises.value = await trainingExerciseService.getAllByTraining(
      trainingId
    );
  };

  const getById = (id: number) => {
    return trainingExercises.value.find((te) => te.id === id) ?? null;
  };

  const deleteData = async (id: number) => {
    await trainingExerciseService.remove(id);
    await getAllData();
  };

  const createData = async (data: ITrainingExercise) => {
    const trainingExercise = await trainingExerciseService.create(data);
    await getAllData();
    return trainingExercise;
  };

  const updateData = async (id: number, data: ITrainingExercise) => {
    await trainingExerciseService.update(id, data);
    await getAllData();
  };

  const getSortedTrainingExercises = () => {
    return [...trainingExercises.value].sort((a, b) => {
      if (a.exercisePosition > b.exercisePosition) return 1;
      if (a.exercisePosition <= b.exercisePosition) return -1;
      return 0;
    });
  };

  onMounted(getAllData);

  return {
    trainingExercises: computed(() => getSortedTrainingExercises()),
    getById,
    deleteData,
    createData,
    updateData,
  };
}
