
import { defineComponent, ref } from 'vue';
import { IHealth } from '@/health/@interfaces/IHealth';
import HealthDataTable from './HealthDataTable.vue';
import HealthForm from '@/health/HealthForm.vue';
import useFormToggler from '@/@shared/@composables/useFormToggler';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import useHealthStore from './@composables/useHealthStore';

export default defineComponent({
  name: 'HealthView',
  components: { HealthForm, HealthDataTable, FontAwesomeIcon },
  setup() {
    const { displayForm, toggleForm } = useFormToggler();
    const healthSelected = ref<IHealth | null>(null);
    const healthStore = useHealthStore();
    const healthList = healthStore.healthList;

    const updateRow = (row: IHealth) => {
      healthSelected.value = row;
      toggleForm();
    };
    const createRow = () => {
      healthSelected.value = null;
      toggleForm();
    };
    const deleteRow = (row: IHealth) => {
      refreshScreen(row, true);
    };
    const refreshScreen = (row: IHealth, toRemove = false) => {
      if (!toRemove) toggleForm();
    };

    return {
      healthList,
      displayForm,
      healthSelected,
      toggleForm,
      updateRow,
      createRow,
      deleteRow,
      refreshScreen,
    };
  },
});
