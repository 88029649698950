import { computed, onMounted, ref } from 'vue';
import { IResult } from '../@interfaces/IResult';
import { resultService } from '../@services/result.service';
import { IWorkout } from '@/workout/@interfaces/IWorkout';
import useWorkoutStore from '@/workout/@composables/useWorkoutStore';

const results = ref<IResult[]>([] as IResult[]);
const previousResults = ref<IResult[]>([] as IResult[]);

export default function useResultStore(workout: IWorkout) {
  const workoutStore = useWorkoutStore();

  const getAllData = async () => {
    results.value = await resultService.getByWorkout(workout.id);
    previousResults.value = await findPreviousResults();
  };

  const getById = (id: number) => {
    return results.value.find((result) => result.id === id) ?? null;
  };

  const deleteData = async (id: number) => {
    await resultService.deleteData(id);
    await getAllData();
  };

  const createData = async (data: IResult) => {
    const result = await resultService.createData(data);
    await getAllData();
    return result;
  };

  const updateData = async (id: number, data: IResult) => {
    await resultService.updateData(id, data);
    await getAllData();
  };

  const findPreviousResults = async () => {
    const previousWorkouts = workoutStore.workouts.value
      .filter(
        (w) =>
          new Date(workout.date).getTime() - new Date(w.date).getTime() > 0 &&
          w.trainingId === workout.trainingId
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const lastWorkout = previousWorkouts[0] || null;

    if (lastWorkout) {
      return resultService.getByWorkout(lastWorkout.id);
    }
    return [];
  };

  onMounted(async () => {
    if (!results.value.length || workout.id !== results.value[0]?.workoutId) {
      await getAllData();
    }
  });

  return {
    results: computed(() => results.value),
    previousResults: computed(() => previousResults.value),
    deleteData,
    createData,
    updateData,
    getById,
  };
}
