import { Ref, ref } from 'vue';

export default function useFormToggler(): {
  displayForm: Ref<boolean>;
  toggleForm: () => void;
} {
  const displayForm = ref(false);
  const toggleForm = () => {
    displayForm.value = !displayForm.value;
  };
  return {
    displayForm,
    toggleForm,
  };
}
