import useExerciseStore from '@/exercise/@composables/useExerciseStore';
import useWorkoutStore from '@/workout/@composables/useWorkoutStore';
import { computed } from 'vue';
import { IExerciseResult } from '../@interfaces/IExerciseResult';
import { ITraining } from '../@interfaces/ITraining';
import { ITrainingResults } from '../@interfaces/ITrainingResults';
import useResultStore from './useResultStore';
import useTrainingExerciseStore from './useTrainingExerciseStore';
import { ChartDataset, ScatterDataPoint } from 'chart.js';
import { transformDateToNumericLocaleString } from '@/@shared/@helpers/date-helper';
import { IResult } from '@/workout/@interfaces/IResult';
import { chartHelper } from '@/@shared/@helpers/chart-helper';
import { userAgentHelper } from '@/@shared/@helpers/user-agent-helper';

export default function useResultChartData(training: ITraining) {
  const { results } = useResultStore(training);
  const { workouts } = useWorkoutStore();
  const { exercises } = useExerciseStore();
  const { trainingExercises } = useTrainingExerciseStore(training.id);

  const trainingResults = computed(() => {
    const mergedArray = [] as ITrainingResults[];
    for (const workout of workouts.value.filter(
      (w) => w.trainingId === training.id
    )) {
      mergedArray.push({
        workout: workout,
        results: results.value.filter((x) => x.workoutId === workout.id),
      });
    }
    addMissingResultsWithNullValues(mergedArray);
    return mergedArray;
  });

  function addMissingResultsWithNullValues(mergedArray: ITrainingResults[]) {
    mergedArray.forEach((x) => {
      trainingExercises.value.forEach((e) => {
        if (!x.results.find((r) => r.trainingExerciseId === e.id)) {
          x.results.push({
            trainingExerciseId: e.id,
            workoutId: x.workout.id,
          } as IResult);
        }
      });
    });
  }

  const sortedData = computed(() => {
    const data = [] as IExerciseResult[];
    trainingResults.value.forEach((x) => {
      x.results.forEach((r) =>
        data.push({
          name:
            exercises.value.find(
              (e) =>
                e.id ===
                trainingExercises.value.find(
                  (te) => te.id === r.trainingExerciseId
                )?.exerciseId
            )?.name ?? '?',
          result: getRepetitionsOrTime(r),
          date: x.workout.date,
          isTimed: !!r.time,
          hasWeight: !!r.weight,
        })
      );
    });
    return data.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  });

  const getRepetitionsOrTime = (result: IResult) => {
    if (result.time) {
      return result.time;
    } else {
      return (
        (result.weight || 1) *
        (result.repetitionsSet1 +
          result.repetitionsSet2 +
          result.repetitionsSet3 +
          result.repetitionsSet4 +
          result.repetitionsSet5 +
          result.repetitionsSet6 +
          result.repetitionsSet7 +
          result.repetitionsSet8 +
          result.repetitionsSet9 +
          result.repetitionsSet10)
      );
    }
  };

  const getDates = () => {
    const dates = sortedData.value.map((x) =>
      transformDateToNumericLocaleString(x.date)
    );
    return [...new Set(dates)]; // remove duplicates
  };

  const getDatasets = () => {
    const datasetsArray: ChartDataset<
      'line',
      (number | ScatterDataPoint | null)[]
    >[] = [];
    sortedData.value.forEach((x, index) => {
      if (!datasetsArray.find((d) => d.label === x.name)) {
        datasetsArray.push({
          label: x.name,
          yAxisID: x.isTimed ? 'y2' : 'y1',
          pointRadius: userAgentHelper.isMobile() ? 0 : 3,
          spanGaps: true,
          borderColor: chartHelper.getColorByKeyOrDefault(index),
          backgroundColor: chartHelper.getBackgroundColorByKeyOrDefault(index),
          data: sortedData.value
            .filter((r) => r.name === x.name)
            .map((d) => d.result),
        });
      }
    });
    return datasetsArray;
  };

  return {
    dates: computed(() => getDates()),
    datasets: computed(() => getDatasets()),
  };
}
