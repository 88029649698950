import axios from 'axios';
import { ITrainingExercise } from '@/training/@interfaces/ITrainingExercise';

export const trainingExerciseService = {
  get,
  getAllByTraining,
  create,
  update,
  remove,
};

const endpoint = '/trainingExercise';

async function get(id: number): Promise<ITrainingExercise> {
  const response = await axios.get<ITrainingExercise>(`${endpoint}/${id}`);
  return response.data;
}

async function getAllByTraining(
  trainingId: number
): Promise<ITrainingExercise[]> {
  const response = await axios.get<ITrainingExercise[]>(
    `${endpoint}/byTraining/${trainingId}`
  );
  return response.data;
}

async function create(
  trainingExercise: ITrainingExercise
): Promise<ITrainingExercise> {
  const response = await axios.post<ITrainingExercise>(
    `${endpoint}`,
    trainingExercise
  );
  return response.data;
}

async function update(
  id: number,
  trainingExercise: ITrainingExercise
): Promise<void> {
  await axios.put(`${endpoint}/${id}`, trainingExercise);
}

async function remove(id: number): Promise<void> {
  await axios.delete(`${endpoint}/${id}`);
}
