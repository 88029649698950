import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5638f52c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "training-creation-view" }
const _hoisted_2 = { class: "nav-secondary" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_training_form = _resolveComponent("training-form")!
  const _component_training_exercises_form = _resolveComponent("training-exercises-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$router.back && _ctx.$router.back(...args)))
      }, "Retour"),
      _createElementVNode("button", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
      }, "Terminé")
    ]),
    (_ctx.training)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.training.name), 1))
      : _createCommentVNode("", true),
    (!_ctx.training)
      ? (_openBlock(), _createBlock(_component_training_form, {
          key: 1,
          onFormSubmitted: _cache[2] || (_cache[2] = ($event: any) => (_ctx.assignTrainingName($event)))
        }))
      : _createCommentVNode("", true),
    (_ctx.training)
      ? (_openBlock(), _createBlock(_component_training_exercises_form, {
          key: 2,
          "training-prop": _ctx.training
        }, null, 8, ["training-prop"]))
      : _createCommentVNode("", true)
  ]))
}