
import { defineComponent } from 'vue';
import HealthForm from '@/health/HealthForm.vue';
import useFormToggler from '@/@shared/@composables/useFormToggler';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import useHealthStore from './@composables/useHealthStore';
import HealthChart from './HealthChart.vue';
import HealthLastDataTable from './HealthLastDataTable.vue';

export default defineComponent({
  name: 'HealthDashboard',
  components: { HealthForm, FontAwesomeIcon, HealthChart, HealthLastDataTable },
  setup() {
    const { displayForm, toggleForm } = useFormToggler();

    const healthStore = useHealthStore();
    const lastHealthData = healthStore.lastHealthData;
    const healthData = healthStore.healthList;

    return {
      displayForm,
      healthData,
      lastHealthData,
      toggleForm,
    };
  },
});
