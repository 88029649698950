import { computed, onMounted, ref } from 'vue';
import { IWorkout } from '../@interfaces/IWorkout';
import { workoutService } from '../@services/workout.service';
import messages from '@/assets/text/messages.json';

const workouts = ref<IWorkout[]>([] as IWorkout[]);

export default function useWorkoutStore() {
  const getAllData = async () => {
    workouts.value = await workoutService.getAll(0, 1000);
  };

  const getById = (id: number) => {
    return workouts.value.find((w) => w.id === id) ?? null;
  };

  const deleteData = async (id: number) => {
    if (!confirm(messages.confirmation.delete)) {
      return;
    }
    await workoutService.deleteData(id);
    await getAllData();
  };

  const createData = async (data: IWorkout) => {
    const workout = await workoutService.createData(data);
    await getAllData();
    return workout;
  };

  const updateData = async (id: number, data: IWorkout) => {
    await workoutService.updateData(id, data);
    await getAllData();
  };

  const getSortedWorkouts = () => {
    return [...workouts.value].sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });
  };

  onMounted(async () => {
    if (!workouts.value.length) {
      await getAllData();
    }
  });

  return {
    workouts: computed(() => getSortedWorkouts()),
    getById,
    deleteData,
    createData,
    updateData,
  };
}
