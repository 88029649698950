import { computed, onMounted, ref } from 'vue';
import { IHealth } from '../@interfaces/IHealth';
import { healthService } from '../@services/health.service';
import messages from '@/assets/text/messages.json';

const healthList = ref<IHealth[]>([] as IHealth[]);
const lastHealthData = ref<IHealth | null>(null);

export default function useHealthStore() {
  const getAllData = async () => {
    healthList.value = await healthService.getAllData(0, 100);
  };

  const getLastData = async () => {
    lastHealthData.value = await healthService.getLastData();
  };

  const deleteData = async (id: number) => {
    if (!confirm(messages.confirmation.delete)) {
      return;
    }
    await healthService.deleteData(id);
    await updateHealthState();
  };

  const createData = async (data: IHealth) => {
    const healthData = await healthService.createData(data);
    await updateHealthState();
    return healthData;
  };

  const updateData = async (id: number, data: IHealth) => {
    await healthService.updateData(id, data);
    await updateHealthState();
  };

  const updateHealthState = async () => {
    await Promise.all([getLastData(), getAllData()]);
  };

  const getSortedHealthList = () => {
    return [...healthList.value].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };

  onMounted(async () => {
    if (!healthList.value.length || !lastHealthData.value) {
      await updateHealthState();
    }
  });

  return {
    healthList: computed(() => getSortedHealthList()),
    lastHealthData: computed(() => lastHealthData.value),
    deleteData,
    createData,
    updateData,
  };
}
