
import { defineComponent } from 'vue';
import HealthDashboard from '@/health/HealthDashboard.vue';
import TrainingDashboard from '@/training/TrainingDashboard.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    HealthDashboard,
    TrainingDashboard,
  },
});
