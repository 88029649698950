
import { defineComponent } from '@vue/runtime-core';
import { ref } from 'vue';
import TrainingForm from '@/training/TrainingForm.vue';
import TrainingExercisesForm from '@/training/TrainingExercisesForm.vue';
import { ITraining } from '@/training/@interfaces/ITraining';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import useTrainingStore from './@composables/useTrainingStore';
import PaginateComponent from '@/@shared/PaginateComponent.vue';

export default defineComponent({
  name: 'TrainingsView',
  components: {
    TrainingForm,
    TrainingExercisesForm,
    FontAwesomeIcon,
    PaginateComponent,
  },
  setup() {
    const trainingToModify = ref<ITraining>({} as ITraining);
    const displayTrainingForm = ref<boolean>(false);

    const { trainings, deleteData } = useTrainingStore();

    const paginatedTrainings = ref<ITraining[]>([]);

    const showTrainingForm = (training: ITraining) => {
      trainingToModify.value = training;
    };
    const hideTrainingForm = () => {
      trainingToModify.value = {} as ITraining;
      displayTrainingForm.value = false;
    };
    const modifyTrainingName = () => {
      displayTrainingForm.value = true;
    };

    const onPagination = (event: unknown[]) => {
      paginatedTrainings.value = event as ITraining[];
    };
    return {
      trainingToModify,
      trainings,
      displayTrainingForm,
      paginatedTrainings,
      deleteTraining: deleteData,
      showTrainingForm,
      hideTrainingForm,
      modifyTrainingName,
      onPagination,
    };
  },
});
