
import { chartHelper } from '@/@shared/@helpers/chart-helper';
import { computed, defineComponent } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart, ChartData, registerables } from 'chart.js';
import useHealthChartData from './@composables/useHealthChartData';
import useHealthChartOptions from './@composables/useHealthChartOptions';

export default defineComponent({
  name: 'HealthChart',
  components: { Line },
  setup() {
    const healthChartData = useHealthChartData();

    Chart.register(...registerables);
    const healthChartOptions = useHealthChartOptions();

    const options = healthChartOptions.options;

    // Using 'any' instead of 'line | bar'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chartData = computed<ChartData<any>>(() => ({
      labels: healthChartData.dates.value,
      datasets: [
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.weightData.value,
          label: 'Poids',
          borderColor: chartHelper.COLORS.red,
          backgroundColor: chartHelper.BACKGROUND_COLORS.red,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.neckData.value,
          label: 'Tour de cou',
          borderColor: chartHelper.COLORS.grey,
          backgroundColor: chartHelper.BACKGROUND_COLORS.grey,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.shouldersData.value,
          label: "Tour d'épaules",
          borderColor: chartHelper.COLORS.orange,
          backgroundColor: chartHelper.BACKGROUND_COLORS.orange,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.chestData.value,
          label: 'Tour de poitrine',
          borderColor: chartHelper.COLORS.darkOrange,
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkOrange,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.waistData.value,
          label: 'Tour de taille',
          borderColor: chartHelper.COLORS.yellow,
          backgroundColor: chartHelper.BACKGROUND_COLORS.yellow,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.forearmData.value,
          label: "Tour d'avant bras",
          borderColor: chartHelper.COLORS.darkBlue,
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkBlue,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.thighData.value,
          label: 'Tour de cuisse',
          borderColor: chartHelper.COLORS.purple,
          backgroundColor: chartHelper.BACKGROUND_COLORS.purple,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.calfData.value,
          label: 'Tour de mollet',
          borderColor: chartHelper.COLORS.darkPurple,
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkPurple,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.bicepsData.value,
          label: 'Tour de bras',
          borderColor: chartHelper.COLORS.blue,
          backgroundColor: chartHelper.BACKGROUND_COLORS.blue,
        },
        {
          ...healthChartOptions.datasetsOptionsLine,
          data: healthChartData.wristData.value,
          label: 'Tour de poignet',
          borderColor: chartHelper.COLORS.darkGrey,
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkGrey,
          hidden: true,
        },
        {
          ...healthChartOptions.datasetsOptionsBar,
          data: healthChartData.fatData.value,
          label: '% de graisse',
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkRed,
        },
        {
          ...healthChartOptions.datasetsOptionsBar,
          data: healthChartData.muscleData.value,
          label: '% de muscles',
          backgroundColor: chartHelper.BACKGROUND_COLORS.darkGreen,
        },
        {
          ...healthChartOptions.datasetsOptionsBar,
          data: healthChartData.waterData.value,
          label: "% d'eau",
          backgroundColor: chartHelper.BACKGROUND_COLORS.blue,
          hidden: true,
        },
      ],
    }));

    return { chartData, options };
  },
});
