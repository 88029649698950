
import { computed, defineComponent, ref } from 'vue';
import { ILogin } from '@/login/@interfaces/ILogin';
import { authService } from '@/@shared/@services/auth.service';

export default defineComponent({
  name: 'LoginForm',
  emits: ['formSubmitted'],
  setup(props, { emit }) {
    const auth = ref<ILogin>({} as ILogin);
    const isValid = computed(
      () =>
        auth.value.usernameOrEmail?.length > 0 &&
        auth.value.password?.length > 0
    );
    const onSubmit = async () => {
      await authService.login(auth.value);
      emit('formSubmitted');
    };
    return { auth, isValid, onSubmit };
  },
});
