import { IResult } from '@/workout/@interfaces/IResult';
import axios from 'axios';

export const resultService = {
  getById,
  getByWorkout,
  getByTraining,
  createData,
  deleteData,
  updateData,
};

async function getById(id: number): Promise<IResult | null> {
  const response = await axios.get<IResult>(`/result/${id}`);
  if (response.status === 204) return null;
  return response.data;
}

async function getByWorkout(workoutId: number): Promise<IResult[]> {
  const response = await axios.get<IResult[]>(`/result/byWorkout/${workoutId}`);
  return response.data;
}

async function getByTraining(trainingId: number): Promise<IResult[]> {
  const response = await axios.get<IResult[]>(
    `/result/byTraining/${trainingId}`
  );
  return response.data;
}

async function createData(result: IResult): Promise<IResult> {
  const response = await axios.post<IResult>('/result', result);
  return response.data;
}

async function deleteData(id: number): Promise<void> {
  await axios.delete(`/result/${id}`);
}

async function updateData(id: number, result: IResult): Promise<void> {
  await axios.put(`/result/${id}`, result);
}
