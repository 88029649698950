import useExerciseStore from '@/exercise/@composables/useExerciseStore';
import useTrainingExerciseStore from '@/training/@composables/useTrainingExerciseStore';
import { computed, ref, watch } from 'vue';
import { IWorkoutExercise } from '../@interfaces/IWorkoutExercise';

export default function useWorkoutExerciseStore(trainingId: number) {
  const workoutExerciseSelected = ref<IWorkoutExercise>({} as IWorkoutExercise);
  const workoutExercises = ref<IWorkoutExercise[]>([] as IWorkoutExercise[]);
  const { trainingExercises } = useTrainingExerciseStore(trainingId);
  const { exercises } = useExerciseStore();

  const getWorkoutExercises = () => {
    workoutExercises.value = [] as IWorkoutExercise[];
    trainingExercises.value.forEach((exercise) => {
      workoutExercises.value?.push({
        trainingExerciseId: exercise.id,
        exerciseName: getExerciseName(exercise.exerciseId),
        trainingExercisePosition: exercise.exercisePosition,
        trainingExerciseCompleted: false,
        isTimedExercise: checkIfTimedExercise(exercise.exerciseId),
        note: exercise.note,
        hasWeight: checkIfExerciseHasWeight(exercise.exerciseId),
      });
    });
  };

  const getExerciseName = (id: number) => {
    return exercises.value.find((item) => item.id === id)?.name ?? '/';
  };
  const checkIfTimedExercise = (id: number): boolean => {
    return exercises.value.find((item) => item.id === id)?.isTimed ?? false;
  };

  const checkIfExerciseHasWeight = (id: number) => {
    return exercises.value.find((item) => item.id === id)?.hasWeight ?? false;
  };

  const getSortedWorkoutExercises = () => {
    return [...workoutExercises.value]?.sort((a, b) => {
      if (a.trainingExercisePosition > b.trainingExercisePosition) return 1;
      if (a.trainingExercisePosition <= b.trainingExercisePosition) return -1;
      return 0;
    });
  };

  const setWorkoutExerciseSelected = (exercise: IWorkoutExercise | null) => {
    workoutExerciseSelected.value = exercise ?? ({} as IWorkoutExercise);
  };

  const setWorkoutExerciseToComplete = () => {
    const exerciseIndex = workoutExercises.value.findIndex(
      (exercise) =>
        exercise.trainingExerciseId ===
        workoutExerciseSelected.value.trainingExerciseId
    );
    workoutExercises.value[exerciseIndex].trainingExerciseCompleted = true;
  };

  watch(() => [trainingExercises.value, exercises.value], getWorkoutExercises);

  return {
    workoutExercises: computed(() => getSortedWorkoutExercises()),
    workoutExerciseSelected: computed(() => workoutExerciseSelected.value),
    setWorkoutExerciseSelected,
    setWorkoutExerciseToComplete,
  };
}
